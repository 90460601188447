import { Box, CircularProgress, IconButton, ImageList, ImageListItem, ImageListItemBar, LinearProgress, Tooltip, Typography } from '@mui/material';
import {
	HiddenFields,
	ItemErrors,
	ListErrors,
	Uploader,
	useMediaLibrary,
	Icons,
} from '@spatie/media-library-pro-react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import CancelIcon from '@mui/icons-material/Cancel';
import debounce from 'lodash.debounce';
import { Image } from 'mui-image';
import { SlideshowLightbox } from 'lightbox.js-react';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const MediaLibraryWrapper = ({ children, state, sortable }) => (
	<div
		className={`media-library media-library-multiple ${state.media.length == 0 ? 'media-library-empty' : 'media-library-filled'
			} ${sortable && 'media-library-sortable'}`}
	>
		{children}
	</div>
);

const PhotosDropzone = forwardRef(({
	name,
	initialValue = [],
	translations = {},
	validationRules,
	validationErrors = {},
	routePrefix,
	sortable = true,
	maxItems,
	maxSizeForPreviewInBytes,
	vapor,
	vaporSignedStorageUrl,
	uploadDomain,
	withCredentials,
	headers,
	fileTypeHelpText,
	setMediaLibrary,
	disabled = false,
	beforeUpload = () => { },
	afterUpload = () => { },
	onChange = () => { },
	onIsReadyToSubmitChange = () => { },
	onDelete = () => { },
}, ref) => {

	const {
		mediaLibrary,
		state,
		getImgProps,
		getFileInputProps,
		getDropZoneProps,
		setOrder,
		removeMedia,
		getErrors,
		clearObjectErrors,
		clearInvalidMedia,
		isReadyToSubmit,
	} = useMediaLibrary({
		name,
		initialValue: initialValue,
		validationErrors: Array.isArray(validationErrors) ? {} : validationErrors,
		routePrefix,
		validationRules,
		translations,
		maxItems,
		maxSizeForPreviewInBytes,
		vapor,
		vaporSignedStorageUrl,
		uploadDomain,
		withCredentials,
		headers,
		beforeUpload,
		afterUpload,
		onChange,
	});

	const dropZoneProps = getDropZoneProps();
	const fileInputProps = getFileInputProps();

	// const items = state.media.map((object) => {
	// 	return {
	// 		id: object.attributes.uuid,
	// 		content: object,
	// 	};
	// });

	const [cols, setCols] = useState(6);
	const [lightboxstartIndex, setLightboxstartIndex] = useState(0);
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

	const calculateCols = useCallback(() => {
		const windowWidth = window.innerWidth;
		let minWidth;

		if (windowWidth >= 1200) {
			// Desktop screens
			minWidth = 400;
		} else if (windowWidth >= 768) {
			// Tablet screens
			minWidth = 300;
		} else {
			// Mobile screens
			minWidth = 250;
		}

		const calculatedCols = Math.max(1, Math.floor(windowWidth / minWidth));
		setCols(calculatedCols);
	}, []);

	// Use lodash.debounce to debounce the calculateCols function
	const debouncedCalculateCols = useCallback(debounce(calculateCols, 200, { leading: true }), []);

	const handleOnDelete = useCallback(() => {
		onDelete();
	}, [onDelete]);

	const debouncedOnDelete = useCallback(debounce(handleOnDelete, 2000, { leading: false, trailing: true }), []);

	const lightBoxImages = useMemo(() => state.media.map((object) => {
		const imgProps = getImgProps(object);
		return {
			src: object.attributes.original_url ?? object.client_preview ?? imgProps.src,
			alt: imgProps.alt,
		};
	}), [getImgProps, state.media])

	useImperativeHandle(ref, () => {
		return {
			clear() {
				const hasItems = state.media.length > 0;
				state.media.forEach((object) => {
					removeMedia(object);
				});

				if (hasItems) {
					debouncedOnDelete();
				}
			},
		};
	}, [debouncedOnDelete, removeMedia, state.media]);


	React.useEffect(() => {
		onIsReadyToSubmitChange(isReadyToSubmit);
	}, [isReadyToSubmit]);

	React.useEffect(() => {
		if (setMediaLibrary && mediaLibrary) {
			setMediaLibrary(mediaLibrary);
		}
	}, [setMediaLibrary, mediaLibrary]);

	useEffect(() => {
		calculateCols(); // Initial calculation
		window.addEventListener('resize', debouncedCalculateCols); // Update on resize

		return () => window.removeEventListener('resize', debouncedCalculateCols); // Cleanup on unmount
	}, [debouncedCalculateCols]);

	return (
		<Box>
			<Icons />
			<MediaLibraryWrapper state={state} sortable={sortable}>
				<ListErrors
					invalidMedia={state.invalidMedia}
					topLevelErrors={Array.isArray(validationErrors) ? undefined : validationErrors[name]}
					onClear={clearInvalidMedia}
				/>
			</MediaLibraryWrapper>


			<ImageList cols={cols} gap={12} sx={{ my: 0 }}>

				{(state.media ?? []).map((object, index) => {
					const imgProps = getImgProps(object);
					const OptionalTooltip = !object.upload.hasFinishedUploading ? React.Fragment : Tooltip;
					const objectErrors = getErrors(object);

					return (
						<ImageListItem key={object.uuid}
							sx={{
								position: 'relative',
								cursor: 'pointer',
								'&:hover .MuiImageListItemBar-root': {
									opacity: 1,
								},
								'& .MuiImageListItemBar-root': {
									transition: 'opacity 0.3s',
									opacity: object.upload.hasFinishedUploading ? 0 : 1,
								},

							}}
							onClick={() => {
								setLightboxstartIndex(index);
								setLightboxIsOpen(true);
							}}
						>
							<Image
								src={imgProps.src}
								alt={imgProps.alt}
								duration={1000}
								sx={{
									aspectRatio: 1
								}}
							/>

							<MediaLibraryWrapper state={state} sortable={sortable}>
								<ItemErrors
									objectErrors={objectErrors}
									onBack={() => clearObjectErrors(object)}
								/>
							</MediaLibraryWrapper>

							<Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
								{!object.upload.hasFinishedUploading && (
									<>
										<LinearProgress color="primary" variant="determinate" value={object.upload.uploadProgress} />
									</>
								)}
							</Box>
							<ImageListItemBar
								subtitle={imgProps.alt}
								actionIcon={
									<OptionalTooltip title="Remove">
										<IconButton
											sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
											onClick={(e) => {
												e.stopPropagation();
												removeMedia(object);
												debouncedOnDelete();
											}}>
											{!object.upload.hasFinishedUploading ? <CircularProgress color='white' size={24} /> : <CancelIcon />}
										</IconButton>
									</OptionalTooltip>
								}
							>
							</ImageListItemBar>
						</ImageListItem>
					)
				})}
			</ImageList>

			<HiddenFields name={name} mediaState={state.media} />

			<SlideshowLightbox
				images={lightBoxImages}
				showThumbnails={true}
				open={lightboxIsOpen}
				lightboxIdentifier="formPhotosLightbox"
				fullScreen
				onClose={() => setLightboxIsOpen(false)}
				nextArrow={
					<IconButton aria-label="Next" color="secondary" variant="outlined">
						<NavigateNextRoundedIcon />
					</IconButton>
				}
				prevArrow={
					<IconButton aria-label="Next" color="secondary" variant="outlined">
						<NavigateBeforeRoundedIcon />
					</IconButton>
				}
				startingSlideIndex={lightboxstartIndex}
			/>

			{(disabled && !state.media.length) && (
				<Box sx={{ mt: 1 }}>
					<Typography>
						No photos
					</Typography>
				</Box>
			)}

			{!disabled && (
				<MediaLibraryWrapper state={state} sortable={sortable}>
					<Box
						className={
							!maxItems || state.media.length < maxItems ? 'media-library-uploader' : 'media-library-hidden'
						}
						sx={{ mt: 1 }}
					>
						<Uploader {...dropZoneProps} {...fileInputProps} add multiple fileTypeHelpText={fileTypeHelpText} />
					</Box>
				</MediaLibraryWrapper>
			)}
		</Box>
	);
});


PhotosDropzone.displayName = 'PhotosDropzone';

export default PhotosDropzone;