import { Box, Avatar as MuiAvatar, Tooltip, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { stringToColor } from '~/helpers/generalHelper';

function nameToInitials(name = "") {
	return name
		? name.trim().split(/\s+/).map(word => word[0].toUpperCase()).join('')
		: null;
}

const Avatar = React.memo(({ name, size = 48, sx = {}, noToolip = false, inline = false, ...props }) => {

	// const params = {
	// 	name,
	// 	rounded: true
	// };

	// const url = new URL("https://ui-avatars.com/api/");
	// url.search = new URLSearchParams(params).toString();
	const theme = useTheme();

	const OptionalTooltip = noToolip ? React.Fragment : Tooltip;
	const bgColour = stringToColor(name);

	return (
		<OptionalTooltip {...(noToolip ? {} : { title: name })}>
			<Box sx={sx} style={{ display: inline ? 'inline-block' : 'block' }}>
				<MuiAvatar sx={{ width: size, height: size, fontSize: (size * 0.4), bgcolor: bgColour, color: theme.palette.getContrastText(bgColour) }} {...props}>
					{nameToInitials(name)}
				</MuiAvatar>
			</Box>
		</OptionalTooltip>
	);
});

Avatar.displayName = 'Avatar';


Avatar.propTypes = {
	name: PropTypes.string
};

export default Avatar;