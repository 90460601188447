import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import GlobalSearch from '~/components/GlobalSearch';
import { searchAssessments } from '~/requests/assessments';
import { newFormSubmission } from '~/requests/submissions';
import AssessmentCard from '../AssessmentCard';
import DialogCloseButton from '../DialogCloseButton';

const StartEstimateDialog = ({ isOpen, onClose }) => {

	const navigate = useNavigate();
	const theme = useTheme();

	const [selectedAssessment, setSelectedAssessment] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClose = useCallback(() => {
		setSelectedAssessment(null);
		onClose();
	}, [onClose]);

	const handleStartTriage = useCallback((selectedAssessment = null) => {
		newFormSubmission('estimate', {
			...(selectedAssessment ? { assessment_uuid: selectedAssessment.uuid } : {})
		})
			.then(({ data: { data: submission } }) => {
				navigate(`/forms/estimate/${submission.uuid}`);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar("Error starting estimate. Please try again", { variant: 'error' });
			})
			.finally(() => {
				handleClose();
			});
	}, [navigate, handleClose]);

	const searchModels = useMemo(() => [
		{
			key: 'assessments',
			label: 'Assessments',
			endpoint: searchAssessments,
			onClick: (item) => {
				setSelectedAssessment(item);
			},
			render: (item, key, onClick) => <AssessmentCard key={key} assessment={item} onClick={onClick} />,
			params: {
				form: 'estimate'
			}
		}

	], [setSelectedAssessment]);

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullWidth
			fullScreen={isMobile}
			className='dialog-align-top'
		>
			<DialogTitle>
				Find Assessment
				<DialogCloseButton onClick={handleClose} />
			</DialogTitle>
			<DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
				{selectedAssessment ? (
					<Card variant="outlined" sx={{ marginTop: 2 }}>
						<CardContent>
							<Typography>
								Claim No: {selectedAssessment.claim_number}
							</Typography>
							<Typography>
								Rego: {selectedAssessment.vehicle.rego}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Facility: {selectedAssessment.facility?.name}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Event: {selectedAssessment.facility?.event?.name}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Customer: {[selectedAssessment.customer?.full_name, selectedAssessment.customer?.company_name].join(' ')}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Vehicle: {[selectedAssessment.vehicle?.build_year || '', selectedAssessment.vehicle?.make || '', selectedAssessment.vehicle?.model || '', selectedAssessment.vehicle?.badge || '', `- ${selectedAssessment.vehicle?.rego || ''}`, `- ${selectedAssessment.vehicle?.colour || ''}`].join(' ')}
							</Typography>
						</CardContent>
						<CardActions>
							<Button size="small" onClick={() => setSelectedAssessment(null)}>Clear</Button>
						</CardActions>
					</Card>
				) : (
					<GlobalSearch models={searchModels} />
				)}

			</DialogContent>
			<DialogActions sx={{ p: 0, mt: 1 }}>
				<Button variant='outlined' color='secondary' onClick={handleClose} sx={{ mr: 'auto' }}>
					Cancel
				</Button>
				<Button onClick={handleStartTriage} variant='contained' color="secondary">
					Skip
				</Button>
				<Button variant='contained' onClick={() => handleStartTriage(selectedAssessment)} autoFocus disabled={!selectedAssessment}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default StartEstimateDialog