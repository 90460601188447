import { Close, Star, VideoLibrary } from '@mui/icons-material';
import { Box, Button, Chip, Drawer, IconButton, List, ListItemButton, ListItemText, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { Field, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { useCallback, useState } from 'react';
import { slotCategories } from '~/data/generalConstants';
import { useConfirm } from '~/hooks/useConfirm';

const BookingsSideDrawer = ({
	date,
	toggleDrawer
}) => {
	const theme = useTheme();

	const [tab, setTab] = useState('bookings');


	return (
		<Drawer
			anchor="right"
			open={!!date}
			onClose={() => toggleDrawer(null)}
			sx={{
				'& .MuiDrawer-paper': {
					[theme.breakpoints.up('md')]: {
						marginTop: `${theme.headerHeight}px`,
					}
				}
			}}
		>
			{!!date && (
				<Box
					component="div"
					sx={{
						padding: theme.spacing(2),
						width: {
							xs: '100vw',
							sm: '90vw',
						},
						maxWidth: 400,
						position: 'relative'
					}}
				>

					<IconButton
						sx={{ position: 'absolute', top: 8, right: 8 }}
						onClick={() => toggleDrawer(null)}
					>
						<Close />
					</IconButton>
					<Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
						<Typography variant="h3" gutterBottom>
							{date.toFormat('EEE, dd MMM yyyy')}
						</Typography>
					</Stack>

					<ToggleButtonGroup
						color="primary"
						size="small"
						fullWidth
						value={tab}
						exclusive
						sx={{ mb: 2 }}
						onChange={(e, val) => setTab(val)}
					>
						<ToggleButton value="bookings">Bookings</ToggleButton>
						<ToggleButton value="slots">Slots</ToggleButton>
					</ToggleButtonGroup>

					{tab === 'bookings' && (
						<BookingsPanel date={date} />
					)}

					{tab === 'slots' && (
						<SlotsPanel date={date} />
					)}

				</Box>
			)}
		</Drawer>
	);
}

const BookingsPanel = ({ date }) => {
	const bookings = [];
	return (
		<List>
			{bookings.length > 0 ? (
				bookings.map((video) => {
					const isRecommended = video.recomended.some(path => location.pathname.startsWith(path));

					return (
						<ListItemButton
							component="a"
							href={video.url}
							key={video.id}
							target="_blank"
							sx={{
								backgroundColor: isRecommended ? 'rgba(0, 123, 255, 0.1)' : 'transparent', // Highlight recommended
								borderLeft: isRecommended ? '4px solid #007bff' : 'none', // Optional left border for recommended
								'&:hover': {
									backgroundColor: isRecommended ? 'rgba(0, 123, 255, 0.2)' : 'rgba(0, 0, 0, 0.04)'
								}
							}}
						>
							{/* Conditionally render the star icon for recommended videos */}
							{isRecommended ? <Star sx={{ mr: 1, color: '#007bff' }} /> : <VideoLibrary sx={{ mr: 1 }} />}

							<ListItemText
								primary={video.title}
								secondary={(
									<Chip
										label={video.category}
										color="primary"
										size="small"
										sx={{ borderRadius: '8px', mt: 1 }}
									/>
								)}
							/>
						</ListItemButton>
					);
				})
			) : (
				<Typography variant="body2">No bookings for this day.</Typography>
			)}
		</List>
	)
}

const SlotsPanel = ({ date }) => {
	const [blockReason, setBlockReason] = useState(null);
	const { openConfirm } = useConfirm();

	const assignSlots = () => {
		// Assign slots
	};

	const handleUnblockDay = () => {
		setBlockReason(null);
	};

	const handleClearSlots = useCallback(() => {
		openConfirm({
			type: 'question',
			title: 'Clear Slots',
			message: <span>Are you sure you want clear the slots for <span style={{ fontWeight: 'bold' }}>{date.toFormat('EEE, dd MMM yyyy')}</span>?</span>,
			onConfirm: (close) => {
				// TODO: Clear slots
				close();
			},
			onCancel: (close) => close()
		});
	}, [openConfirm]);

	const handleBlockDay = useCallback(() => {
		openConfirm({
			type: 'info',
			title: 'Block Day',
			message: <span>To block <span style={{ fontWeight: 'bold' }}>{date.toFormat('EEE, dd MMM yyyy')}</span>, type in a reason below:</span>,
			cancelButtonText: 'Cancel',
			InputComponent: TextField,
			inputProps: { label: "Reason for block" },
			onConfirm: (close, value) => {
				setBlockReason(value);
				close();
			},
			onCancel: (close) => close()
		});
	}, [openConfirm]);

	return (
		<Formik initialValues={{ categories: slotCategories }} onSubmit={assignSlots}>
			{({ handleSubmit }) => (
				<>
					<Stack spacing={1} mb={3} position="relative">
						{slotCategories.map((category, index) => (
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<div>{category.name}</div>
								<Field
									key={index}
									name={`categories.${index}.default_slots`}
									component={FormikTextField}
									variant="standard"
									type="number"
									disabled={!!blockReason}
									style={{ width: '40%' }}
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Box>
						))}
						{blockReason && (
							<div className="content-overlay">
								<Typography variant="body2">DAY BLOCKED:</Typography>
								<div style={{ fontWeight: 'bold' }}>{blockReason}</div>
							</div>
						)}
					</Stack>

					<Button color="primary" variant="contained" fullWidth disabled={!!blockReason} sx={{ mb: 2 }} onClick={() => handleSubmit}>
						Assign Slots
					</Button>

					<Stack direction="row" spacing={1}>
						<Button color="secondary" variant="outlined" fullWidth disabled={!!blockReason} onClick={handleClearSlots}>
							Clear Slots
						</Button>
						{blockReason ? (
							<Button color="secondary" variant="contained" fullWidth onClick={handleUnblockDay}>
								Unblock Day
							</Button>
						) : (
							<Button color="secondary" variant="outlined" fullWidth onClick={handleBlockDay}>
								Block Day
							</Button>
						)}
					</Stack>
				</>
			)}
		</Formik>
	);
}

export default BookingsSideDrawer;