import { lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";


import * as Sentry from "@sentry/react";
import { QueryClient } from '@tanstack/react-query';
import Loader from './components/Loader';
import loadable from './helpers/loadable.jsx';
import { generateCrudRoutes } from './helpers/routerHelper';
import MainLayout from './layout/MainLayout';
import { debtorLoader, debtorsLoader } from './loaders/debtorsLoader';
import { departmentLoader, departmentsLoader } from './loaders/departmentsLoader';
import { estimateFormLoader } from './loaders/estimateFormLoader';
import { eventLoader, eventsLoader } from './loaders/eventsLoader';
import { facilitiesLoader, facilityLoader } from './loaders/facilitiesLoader';
import { paintCodeLoader, paintCodesLoader } from './loaders/paintCodeLoader';
import { userLoader, usersLoader } from './loaders/userLoader';
import { workproviderLoader, workprovidersLoader } from './loaders/workproviderLoader';
import ChangeLogPage from './pages/ChangeLogPage';
import ErrorPage from './pages/ErrorPage';
import ProfilePage from './pages/ProfilePage';
import Bookings from './pages/bookings/BookingsPage';
import FormEstimate from './pages/forms/FormEstimate';
import DepartmentEditPage from './pages/manage/departments/DepartmentEditPage';
import DepartmentNewPage from './pages/manage/departments/DepartmentNewPage';
import EventEditPage from './pages/manage/events/EventEditPage';
import EventNewPage from './pages/manage/events/EventNewPage';
import EventsPage from './pages/manage/events/EventsPage';

const Login = loadable(lazy(() => import('~/pages/Login')));
const Dashboard = loadable(lazy(() => import('~/pages/Dashboard')));
const EstimatesDashboard = loadable(lazy(() => import('~/pages/EstimatesDashboard')));
// const FormEstimate = loadable(lazy(() => import('~/pages/forms/FormEstimate')));
const FormAudit = loadable(lazy(() => import('~/pages/forms/FormAudit')));

const AuditsDashboard = loadable(lazy(() => import('~/pages/AuditsDashboard')));

const FacilitiesPage = loadable(lazy(() => import('~/pages/manage/facilities/FacilitiesPage')));
const FacilitiesNewPage = loadable(lazy(() => import('~/pages/manage/facilities/FacilityNewPage')));
const FacilityEditPage = loadable(lazy(() => import('~/pages/manage/facilities/FacilityEditPage')));

const UsersPage = loadable(lazy(() => import('~/pages/manage/users/UsersPage')));
const UserNewPage = loadable(lazy(() => import('~/pages/manage/users/UserNewPage')));
const UserEditPage = loadable(lazy(() => import('~/pages/manage/users/UserEditPage')));

const PaintCodesPage = loadable(lazy(() => import('~/pages/manage/paint-codes/PaintCodesPage')));
const PaintCodeNewPage = loadable(lazy(() => import('~/pages/manage/paint-codes/PaintCodeNewPage')));
const PaintCodeEditPage = loadable(lazy(() => import('~/pages/manage/paint-codes/PaintCodeEditPage')));

const DepartmentsPage = loadable(lazy(() => import('~/pages/manage/departments/DepartmentsPage')));

const DebtorsPage = loadable(lazy(() => import('~/pages/manage/debtors/DebtorsPage')));
const DebtorsNewPage = loadable(lazy(() => import('~/pages/manage/debtors/DebtorsNewPage')));
const DebtorsEditPage = loadable(lazy(() => import('~/pages/manage/debtors/DebtorsEditPage')));

const WorkprovidersPage = loadable(lazy(() => import('~/pages/manage/workproviders/WorkprovidersPage')));
const WorkproviderNewPage = loadable(lazy(() => import('~/pages/manage/workproviders/WorkproviderNewPage')));
const WorkproviderEditPage = loadable(lazy(() => import('~/pages/manage/workproviders/WorkproviderEditPage')));

const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const queryClient = new QueryClient();


const guestRouter = sentryCreateBrowserRouter([
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "*",
		element: <Navigate to="/login" replace />,
	}
]);

const authRouter = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <Dashboard />
			},
			{
				path: "estimates",
				element: <EstimatesDashboard />,
			},
			{
				path: "bookings",
				element: <Bookings />,
			},
			{
				path: "audits",
				element: <AuditsDashboard />,
			},
			{
				path: "manage",
				children: [
					generateCrudRoutes({
						basePath: 'debtors',
						crudPage: <DebtorsPage />,
						newPage: <DebtorsNewPage />,
						editPage: <DebtorsEditPage />,
						crudLoader: debtorsLoader,
						editLoader: debtorLoader,
					}),
					generateCrudRoutes({
						basePath: 'departments',
						crudPage: <DepartmentsPage />,
						newPage: <DepartmentNewPage />,
						editPage: <DepartmentEditPage />,
						crudLoader: departmentsLoader,
						editLoader: departmentLoader,
					}),
					generateCrudRoutes({
						basePath: 'events',
						crudPage: <EventsPage />,
						newPage: <EventNewPage />,
						editPage: <EventEditPage />,
						crudLoader: eventsLoader,
						editLoader: eventLoader,
					}),
					generateCrudRoutes({
						basePath: 'facilities',
						crudPage: <FacilitiesPage />,
						newPage: <FacilitiesNewPage />,
						editPage: <FacilityEditPage />,
						crudLoader: facilitiesLoader,
						editLoader: facilityLoader,
					}),
					generateCrudRoutes({
						basePath: 'paint-codes',
						crudPage: <PaintCodesPage />,
						newPage: <PaintCodeNewPage />,
						editPage: <PaintCodeEditPage />,
						crudLoader: paintCodesLoader,
						editLoader: paintCodeLoader,
					}),
					generateCrudRoutes({
						basePath: 'users',
						crudPage: <UsersPage />,
						newPage: <UserNewPage />,
						editPage: <UserEditPage />,
						crudLoader: usersLoader,
						editLoader: userLoader,
					}),
					generateCrudRoutes({
						basePath: 'workproviders',
						crudPage: <WorkprovidersPage />,
						newPage: <WorkproviderNewPage />,
						editPage: <WorkproviderEditPage />,
						crudLoader: workprovidersLoader,
						editLoader: workproviderLoader,
					}),
				]
			},
			{
				path: "me",
				element: <ProfilePage />,
			},
			{
				path: "changelog",
				element: <ChangeLogPage />,
			},
		]
	},


	// Forms
	{
		path: "forms/estimate/:submissionId/*",
		element: <FormEstimate />,
		loader: estimateFormLoader(queryClient),
		errorElement: <Navigate to="/estimates" />,
	},
	{
		path: "forms/estimate/",
		element: <Navigate to="/estimates" replace />,
	},
	{
		path: "forms/estimate_audit/:submissionId/*",
		element: <FormAudit />,
		loader: estimateFormLoader(queryClient),
		errorElement: <Navigate to="/audits" />,
	},
	{
		path: "forms/estimate_audit/",
		element: <Navigate to="/audits" replace />,
	},

	{
		path: "*",
		element: <Navigate to="/" replace />,
	}
]);

const Router = () => {
	const { authenticated } = useAuth();

	const router = authenticated ? authRouter : guestRouter;

	if (import.meta.hot) {
		import.meta.hot.dispose(() => router.dispose());
	}

	return (
		<RouterProvider router={router} fallbackElement={<Loader />} future={{ v7_startTransition: true }} />
	);
}


export default Router;