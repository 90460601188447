import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, useTheme, useMediaQuery, Divider, DialogActions, Button, DialogContent, Stack, Box, Collapse, Alert } from '@mui/material';
import { bindDialog } from 'material-ui-popup-state';
import SearchIcon from '@mui/icons-material/Search';
import config from '~/config';
import SearchField from '~/components/SearchField';
import { LoadingButton } from '@mui/lab';
import QuoteCard from '~/components/QuoteCard';
import { Link } from 'react-router-dom';

const IBSSyncDialog = ({
	dialogState,
	quotes = [],
	onQuoteSelection
}) => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// this is messy
	const [view, setView] = useState("select");

	const [searchTerm, setSearchTerm] = useState("");
	const [manualSearchEnabled, setManualSearchEnabled] = useState(false);

	const [linkSearching, setLinkSearching] = useState(false);
	const [showTempError, setShowTempError] = useState(false);

	const [selectedQuote, setSelectedQuote] = useState(null);
	const [isProcessingLink, setIsProcessingLink] = useState(false);

	const ref = useRef(null);

	const handleListItemClick = (quote) => {
		setSelectedQuote(quote);
		setView("confirm");
	};

	const handleManualLookup = () => {
		// item clicked
		setManualSearchEnabled(true);
	};

	const findViaQuoteNumber = () => {
		if (searchTerm.length < 2) return;
		// perform lookup of quote number 
		setLinkSearching(true);

		setTimeout(() => {
			setLinkSearching(false);
			setShowTempError(true);
		}, 2000);
	};

	const linkQuote = () => {
		setIsProcessingLink(true);
		onQuoteSelection(selectedQuote, () => {
			setIsProcessingLink(false);
		});
	};

	useEffect(() => {
		setManualSearchEnabled(!quotes.length);
	}, [quotes]);

	return (
		<Dialog {...bindDialog(dialogState)} fullScreen={isMobile}>
			{view === "select" && (
				<DialogTitle>Select matching quote</DialogTitle>
			)}
			{view === "confirm" && (
				<DialogTitle>Confirm quote</DialogTitle>
			)}

			{view === "select" && (
				<DialogContent>
					{!manualSearchEnabled && (
						<List sx={{ pt: 0 }}>
							{quotes.map((quote) => (
								<ListItem disableGutters key={quote.id}>
									<ListItemButton onClick={() => handleListItemClick(quote)}>
										<ListItemAvatar>
											<Avatar
												variant="square"
												src={`${config.api.base_url}/export/ibs/image/${quote.imageId}`}
											/>
										</ListItemAvatar>
										<ListItemText primary={`${quote.estimateNumber} | ${quote.vehicleRegistration}`} secondary={`${quote.insurerName} | ${quote.ownerDisplayName} | ${quote.vehicleMake} ${quote.vehicleModel}`} />
									</ListItemButton>
								</ListItem>
							))}
							{quotes.length === 0 && (
								<ListItem disableGutters>
									<ListItemButton>
										<ListItemText primary="No quotes found" />
									</ListItemButton>
								</ListItem>
							)}
							<Divider />
							<ListItem disableGutters>
								<ListItemButton
									autoFocus
									onClick={handleManualLookup}
								>
									<ListItemAvatar>
										<Avatar>
											<SearchIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="None of the above?" />
								</ListItemButton>
							</ListItem>
						</List>
					)}

					{manualSearchEnabled && (
						<>
							{showTempError && (
								<Collapse in={true} sx={{ mb: 1 }}>
									<Alert severity="error" onClose={() => setShowTempError(false)}>Unable to find quote number</Alert>
								</Collapse>
							)}

							<SearchField
								inputRef={ref}
								placeholder='Quote Number'
								minLength={2}
								onChange={setSearchTerm}
								isLoading={false}
							/>
						</>
					)}
				</DialogContent>
			)}


			{view === "confirm" && (
				<DialogContent>
					<QuoteCard quote={selectedQuote} />
					<Button
						variant="outlined"
						component={Link}
						to={`https://app.ibodyshop.com/#/Estimate/${selectedQuote.id}/header`}
						target="_blank"
						fullWidth
						sx={{ mt: 1 }}
					>
						View quote in IBS
					</Button>
				</DialogContent>
			)}


			{view === "select" && (
				<DialogActions>
					{manualSearchEnabled && (
						<>
							<Box sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%'
							}}>
								<Button onClick={() => setManualSearchEnabled(false)}>Go back</Button>
								<LoadingButton
									variant="contained"
									onClick={findViaQuoteNumber}
									loading={linkSearching}
								>
									Link
								</LoadingButton>
							</Box>
						</>
					)}
				</DialogActions>
			)}
			{view === "confirm" && (
				<DialogActions>
					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%'
					}}>
						<Button onClick={() => { setView("select"); setSelectedQuote(null); }}>Go back</Button>
						<LoadingButton
							variant="contained"
							onClick={linkQuote}
							loading={isProcessingLink}
						>
							Confirm
						</LoadingButton>
					</Box>
				</DialogActions>
			)}

		</Dialog>
	);
}

export default IBSSyncDialog;