import { useQuery } from '@tanstack/react-query';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getFacilities } from '~/requests/facilities';
import TextDropdown from '../Dropdowns/TextDropdown';

const FilterFacilitiesDropdown = ({ value, workproviderId = null, multiselect = true, onChange }) => {
  const { data: data = [], isLoading } = useQuery({
    queryKey: ['facilities', workproviderId],
    queryFn: queryFunctionHelper(getFacilities, { workprovider_uuid: workproviderId }),
    staleTime: 1000 * 60 * 5,
  });

  return (
    <TextDropdown
      name="facilities"
      label="Facilities"
      options={data}
      multiSelect={multiselect}
      dataKey="uuid"
      dataText="name"
      selected={value}
      fullWidth
      loading={isLoading}
      onChange={onChange}
    />
  )
}

export default FilterFacilitiesDropdown