import { DateTime } from "luxon";
import React, { useState } from "react";

// context setup
const BookingSlotsDialogContext = React.createContext({
	selectedFacility: null,
	setSelectedFacility: null,
	dateRange: [null, null],
	setDateRange: null,
	selectedDays: new Set(),
	setSelectedDays: null,
	modifiedDays: {},
	setModifiedDays: null
});

export const __BookingSlotsDialogContext = BookingSlotsDialogContext;

export const BookingSlotsDialogProvider = ({ children }) => {
	const [selectedFacility, setSelectedFacility] = useState(null);
	const [dateRange, setDateRange] = useState([DateTime.now().startOf('month'), DateTime.now().endOf('month')]);
	const [selectedDays, setSelectedDays] = useState(() => new Set());
	const [modifiedDays, setModifiedDays] = useState({});

	return (
		<BookingSlotsDialogContext.Provider
			value={{
				selectedFacility,
				setSelectedFacility,
				dateRange,
				setDateRange,
				selectedDays,
				setSelectedDays,
				modifiedDays,
				setModifiedDays
			}}
		>
			{children}
		</BookingSlotsDialogContext.Provider>
	);
};