import { InputAdornment } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useCallback, useMemo } from "react";
import { mergeValuesWithInital } from "~/helpers/dataHelpers";
import { useForm } from "~/hooks/useForm";
import CustomList from "../../components/CustomList";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import { getRandomString } from "../../helpers";
import { baseMiscItems } from "./data/data";



const PageMisc = () => {
	const { getMiscPrice, formik, isCompleted } = useForm();

	const addItem = useCallback((arrayHelpers, list) => {
		arrayHelpers.push({
			key: `usr_${getRandomString(8)}`,
			label: "New Item",
			price: 1,
		});
	}, []);
	const miscItems = useMemo(() => {
		return mergeValuesWithInital(formik.values["misc"], baseMiscItems).map(item => ({
			...item,
			price: item.price ?? getMiscPrice('misc', item.key)
		}));
	}, [formik.values, getMiscPrice]);

	return (
		<EstimateFormPage>
			<CustomList.Main
				name="misc"
				headers={[["Item"], ["Price", 10]]}
				baseItems={baseMiscItems}
				values={miscItems}
				allowAdd={addItem}
				readOnly={isCompleted}
			>
				<CustomList.Title>Consumables / Misc</CustomList.Title>

				<CustomList.List>
					{(list) => {
						return list.map((item) => (
							<CustomList.ListItem key={item.key} value={item}>
								{({ index, isVirtual }) => (
									<>
										<CustomList.ListColumn>
											<CustomList.Checkbox
												label={isVirtual ? '' : item.label}
												value={item}
											/>
											{isVirtual && (
												<Field
													component={TextField}
													name={`misc.[${index}].label`}
												/>
											)}
										</CustomList.ListColumn>
										<CustomList.ListColumn center>
											<Field
												component={TextField}
												name={`misc.[${index}].price`}
												variant="standard"
												type="number"
												style={{ minWidth: 60 }}
												InputProps={{
													readOnly: !isVirtual,
													startAdornment: <InputAdornment position="start">$</InputAdornment>,
													defaultValue: item.price,
													inputProps: { min: 0 },
												}}
											/>
										</CustomList.ListColumn>
									</>
								)}
							</CustomList.ListItem>
						))
					}}
				</CustomList.List>
			</CustomList.Main>

		</EstimateFormPage>
	);
};

export default PageMisc;