import { any } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { encode, decode } from 'js-base64';

// context setup
const FilterBarContext = React.createContext({
	getFilterValue: (key) => any,
	updateFilterValue: (key, value) => { },
	clearFilterValue: (key) => { },
	resetFilters: () => { },
	filters: {}
});

export const __FilterBarContext = FilterBarContext;


const anon = _ => _; // static reference important for the use effect. Don't remove. I know it looks random but the props need a reference to a function other wise the code doesn't work

export const FilterBarProvider = ({ serialize = anon, unserialize = anon, defaults = {}, children }) => {

	const { pathname } = useLocation();
	const [local, setLocal] = useLocalStorage(`${__APP_VERSION__}:filters:${pathname}`, null);
	const [searchParams, setSearchParams] = useSearchParams();

	const [filters, setFilters] = useState(defaults);

	const getFilterValue = useCallback((key) => {
		return filters[key] ?? undefined;
	}, [filters]);

	const updateFilterValue = useCallback((key, value) => {
		setFilters((prevData) => {
			return {
				...prevData,
				[key]: value
			};
		});
	}, []);

	const clearFilterValue = useCallback((key) => {
		setFilters((prevData) => {
			const { [key]: _, ...rest } = prevData;
			return rest;
		});
	}, []);

	const resetFilters = useCallback(() => {
		setFilters({});
	}, []);

	useEffect(() => {
		if (local) {
			setFilters(unserialize(local));
		}
	}, []); // load on mount


	useEffect(() => {
		setLocal(serialize(filters));
		console.log("changed");
	}, [filters, serialize, setLocal]);

	return (
		<FilterBarContext.Provider
			value={{ getFilterValue, updateFilterValue, clearFilterValue, resetFilters, filters }}
		>
			{children}
		</FilterBarContext.Provider>
	);
};