import { Close } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table } from '@mui/material'
import { DateCalendar, DatePicker } from '@mui/x-date-pickers-pro'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useJobBookingDialog } from '~/hooks/dialogs/useJobBookingDialog'
import { searchSubmissions } from '~/requests/submissions'
import FacilityDropdown from '../Dropdowns/FacilityDropdown'
import GlobalSearch from '../GlobalSearch'
import SubmissionSummaryTile from '../SubmissionSummaryTile'

const JobBookingDialog = ({ isOpen, onClose }) => {
  const { estimate } = useJobBookingDialog();
  const [selectedEstimate, setSelectedEstimate] = useState(estimate);
  const [facilityId, setFacilityId] = useState(null);
  const [fromDate, setFromDate] = useState(DateTime.now());
  const [availableDates, setAvailableDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setSelectedEstimate(estimate);
  }, [estimate]);

  const handleClose = useCallback(() => {
    setSelectedEstimate(null);
    onClose();
  }, [onClose]);

  const searchModels = useMemo(() => [
    {
      key: 'submissions',
      label: 'Estimates',
      endpoint: searchSubmissions,
      onClick: (item) => {
        setSelectedEstimate(item);
      },
      render: (item, key, onClick) => <SubmissionSummaryTile key={key} submission={item} onClick={onClick} showAuthor />,
      params: {
        form: 'estimate'
      }
    }

  ], [setSelectedEstimate]);

  const fetchAvailableDates = useCallback(() => {
    const maxDays = 90;
    const numberOfDates = 14;
    const randomDates = new Set();

    while (randomDates.size < numberOfDates) {
      const randomOffset = Math.floor(Math.random() * maxDays); // Random day offset within 3 months
      const newDate = fromDate.plus({ days: randomOffset }).toISODate();
      randomDates.add(newDate);
    }

    setAvailableDates([...randomDates]);
  }, [fromDate]);

  const shouldDisableDate = (date) => {
    if (!availableDates) return true;
    const isoDate = date.toISODate();
    return !availableDates.includes(isoDate);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between">
        <div>Job Booking</div>
        <IconButton aria-label="close" onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!selectedEstimate ? (
          <GlobalSearch models={searchModels} />
        ) : (
          <Box display="flex" gap={3}>
            <Box width="50%">
              <Card variant="outlined">
                <CardContent>
                  <Table>
                    <tr>
                      <td>Type</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.type}</td>
                    </tr>
                    <tr>
                      <td>Claim No</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.claim_number}</td>
                    </tr>
                    <tr>
                      <td>Rego</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.rego}</td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.customer}</td>
                    </tr>
                    <tr>
                      <td>Vehicle</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.vehicle}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.mobile}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.email}</td>
                    </tr>
                  </Table>
                </CardContent>
              </Card>
            </Box>
            <Box width="50%" display="flex" flexDirection="column" gap={2}>
              <FacilityDropdown
                value={facilityId}
                fullWidth
                onChange={(name, value) => setFacilityId(value)}
              />
              <DatePicker
                label="From Booking Date"
                value={fromDate}
                fullWidth
                format="dd MMM yyyy"
                displayWeekNumber
                disablePast
                onChange={value => setFromDate(value)}
              />
              <Button color="primary" variant="contained" fullWidth onClick={fetchAvailableDates}>
                Find Available Dates
              </Button>

              <Box>
                <DateCalendar
                  value={selectedDate}
                  shouldDisableDate={shouldDisableDate}
                  sx={{
                    width: "100%",
                    ".MuiPickersCalendar-root": {
                      width: "100%",
                    },
                    ".MuiTypography-root": {
                      fontSize: "1rem",
                    },
                    ".MuiPickersDay-root": {
                      fontSize: "1rem",
                    },
                    ".MuiPickersDay-root:not(.Mui-disabled)": {
                      fontWeight: "bold",
                    },
                  }}
                  onChange={setSelectedDate}
                />
                {!availableDates && (
                  <Box textAlign="center">No available dates</Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose} sx={{ mr: 'auto' }}>
          Cancel
        </Button>
        {selectedEstimate && (
          <>
            <Button variant='outlined' onClick={() => setSelectedEstimate(null)}>
              Back
            </Button>
            <Button variant='contained' disabled={!selectedDate} onClick={handleClose}>
              Create Booking
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default JobBookingDialog