import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getFacilities } from '~/requests/facilities';

const FacilityDropdown = memo(({
	value,
	fullWidth = false,
	formikMeta,
	useUuid = false,
	returnObject = false,
	workproviderId = null,
	showNone = true,
	onChange,
	...props
}) => {
	const { data: facilities = [], isLoading: isFacilitiesLoading } = useQuery({
		queryKey: ['facilities', workproviderId],
		queryFn: queryFunctionHelper(getFacilities, { workprovider_uuid: workproviderId })
	});

	const menuOptions = useMemo(() => {
		return [
			...(showNone ? [{ id: null, name: 'None' }] : []),
			...facilities
		];
	}, [facilities])

	return (
		<FormControl fullWidth={fullWidth} error={formikMeta?.touched && formikMeta?.error ? true : false}>
			<InputLabel id="facility-label">Facility</InputLabel>
			<Select
				labelId="facility-label"
				label="Facility"
				id={props.name || "facility_id"}
				value={value || ""}
				disabled={isFacilitiesLoading}
				onChange={e => {
					const value = e.target.value;
					onChange(props.name || 'facility_id', returnObject
						? menuOptions.find(item => useUuid ? item.uuid === value : item.id === value)
						: value
					);
				}}
			>
				{menuOptions.map(item => (
					<MenuItem key={item.id} value={useUuid ? item.uuid : item.id}>
						{item.name}
					</MenuItem>
				))}
			</Select>
			{formikMeta?.touched && formikMeta?.error && (
				<FormHelperText>{formikMeta.error}</FormHelperText>
			)}
		</FormControl>
	)
});
FacilityDropdown.displayName = 'FacilityDropdown';

export default FacilityDropdown