import { useQuery } from '@tanstack/react-query';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getWorkproviders } from '~/requests/workproviders';
import TextDropdown from '../Dropdowns/TextDropdown';

const FilterWorkProviderDropdown = ({ value, multiselect = false, onChange }) => {
  const { data: data = [], isLoading } = useQuery({
    queryKey: ['workproviders'],
    queryFn: queryFunctionHelper(getWorkproviders)
  });

  return (
    <TextDropdown
      name="workprovider_uuid"
      label="Work Provider"
      options={data}
      multiSelect={multiselect}
      dataKey="uuid"
      dataText="name"
      selected={value}
      fullWidth
      loading={isLoading}
      onChange={onChange}
    />
  )
}

export default FilterWorkProviderDropdown