import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const PageTitle = memo(({ children, icon = null, subtitle = '', gutterBottom = true, ...rest }) => {
	return (
		<Box sx={{
			mb: gutterBottom ? 2 : 0,
			display: 'flex',
			alignItems: 'center'
		}}>
			{icon && React.cloneElement(icon, {
				sx: {
					fontSize: 50,
					mr: 2
				}
			})}
			<Stack>
				<Typography variant="h1" component="h1" {...rest}>
					{children}
				</Typography>
				{subtitle && (
					<Typography variant="subtitle2">
						{subtitle}
					</Typography>
				)}
			</Stack>
		</Box>
	);
});

PageTitle.displayName = 'PageTitle';

PageTitle.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	icon: PropTypes.node,
	subtitle: PropTypes.string,
};
export default PageTitle;