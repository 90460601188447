import { enqueueSnackbar } from 'notistack';
import React from 'react';

/** @private is the given object a Function? */
export const isFunction = (obj) =>
	typeof obj === 'function';

/** @private Does a React component have more then 1 child? */
export const manyChildren = (children) =>
	React.Children.count(children) > 1;

/** @private Does a React component have exactly 0 children? */
export const isEmptyChildren = (children) =>
	React.Children.count(children) === 0;

export const getInsurerColour = (insurer) => {
	if (insurer == 'RACQ') {
		return '#003478';
	}
	if (insurer == 'YOUI') {
		return '#000000';
	}
	if (insurer == 'OTHER') {
		return '#09896b';
	}

	return 'transparent';
}

export const stringToColor = (str) => {
	if (!str) return '#000000';
	// Simple hash function to generate a numeric value based on the input string
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	// Convert the hash to a positive number
	hash = Math.abs(hash);

	// Generate a hex color code
	const color = '#' + (hash % 0xFFFFFF).toString(16).padStart(6, '0');
	return color;
}

// return a list of errors from an api error response, if delimiter is null then return the array of messages
export const getErrorMessages = (response, delimiter = "\n") => {
	if (!response) return;
	if (response?.data?.errors) {
		const errorMessagesObject = response?.data?.errors;
		let errors = [];
		Object.keys(errorMessagesObject).forEach(function (key) {
			var value = errorMessagesObject[key]; /// get each object key pair of error mesages (i.e pin: [array])
			for (let i = 0; i < value.length; i++) {
				errors.push(value[i]);
			}
		});
		return delimiter === null ? errors : errors.join(delimiter);
	}
	else if (response?.data?.data?.message) return response?.data?.data?.message;
	else if (response?.data?.exception?.message) return response?.data?.exception?.message;
}

export const setFormikErrors = (formikRef, errors) => {
	if (errors && formikRef.current) {
		for (const [key, value] of Object.entries(errors)) {
			formikRef.current.setFieldError(key, value);
		}
	}
}

export const copyToClipboard = (string) => {
	const input = document.createElement('input');
	input.value = string;
	document.body.appendChild(input);
	input.select();
	document.execCommand('copy');
	document.body.removeChild(input);
	enqueueSnackbar('Copied to clipboard', { variant: 'info' });
};