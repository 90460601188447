import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleIcon from '@mui/icons-material/Circle';
import { LoadingButton } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Skeleton, Stack, Tab, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePopupState } from "material-ui-popup-state/hooks";
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from "react";
import { Link } from 'react-router-dom';
import ActivityLog from "~/components/ActivityLog";
import SyncFailAlert from "~/components/SyncFailAlert";
import ValueLabel from '~/components/ValueLabel';
import { capitalizeFirstLetter, toDate, toHumanReadable } from '~/helpers/stringHelpers';
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { useForm } from "~/hooks/useForm";
import { useIBSSync } from "~/hooks/useIBSSync";
import { exportSubmission, getQuotes } from "~/requests/submissions";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import IBSSyncDialog from "./dialogs/ IBSSyncDialog";

const SyncStatusDot = ({ status }) => {

	const colour = useMemo(() => {
		switch (status) {
			case "completed":
				return "success";
			case "requires_intervention":
				return "info";
			case "failed":
				return "error";
			case "partial_failed":
				return "warning";
			default:
				return "action";
		}
	}, [status]);

	return (
		<Tooltip title={capitalizeFirstLetter(status)}>
			<CircleIcon color={colour} sx={{ fontSize: 16, marginRight: 1 }} />
		</Tooltip>
	)
};

const PageExport = () => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const queryClient = useQueryClient();

	const { submission } = useForm();
	const { proposeSync, isProcessing, enqueueSync } = useIBSSync(submission);

	const [foundQuotes, setFoundQuotes] = React.useState([]);
	const [syncFailAlertOpen, setSyncFailAlertOpen] = React.useState(false);
	const [syncFailMessage, setSyncFailMessage] = React.useState(null);
	const [syncMenuOpen, setSyncMenuOpen] = React.useState(false);
	const [activeSync, setActiveSync] = React.useState(0);
	const [emailExporting, setEmailExporting] = React.useState(false);

	const anchorRef = React.useRef(null);

	const { data: quotes = [], isLoading: quotesLoading } = useQuery({
		queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'quotes'],
		queryFn: queryFunctionHelper(getQuotes, submission?.uuid),
		staleTime: 1000 * 60 * 5,
		enabled: !!submission?.uuid
	});

	const dialogState = usePopupState({
		variant: 'dialog',
		popupId: 'IBSSyncDialog',
	});


	const handleSendToIBS = useCallback((force = false) => {
		proposeSync(force, (data, success) => {
			switch (data.action) {
				// return list of quotes 
				case "quote_matches":
					// user to select appropiate quote form list 
					setFoundQuotes(data.results);
					dialogState.open();
					break;
				case "no_results":
					// show error message
					// setSyncFailMessage("No matching IBS quote was found. This will be sent to office to process manually.");
					// setSyncFailAlertOpen(true);
					dialogState.open();

					// open dialog here to manually map the quote
					break;
				case "direct_match":
					enqueueSnackbar("Sync has been initiated.");

					break;
				default:
					if (success) enqueueSnackbar(data.message ?? "Sync has been initiated.");
					break;
			}

			queryClient.invalidateQueries({
				queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'exports'],
			});

			queryClient.invalidateQueries({
				queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'quotes'],
			});
		});
	}, [proposeSync, queryClient, submission?.uuid, dialogState]);

	const exportEmail = useCallback((self = false) => {

		setEmailExporting(true);
		exportSubmission(submission.uuid, {
			action: "email",
			self: self.toString()
		})
			.then((response) => {
				enqueueSnackbar(response?.data?.data?.message);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(error.response?.data?.data?.message || error.message, { variant: 'error' });
			})
			.finally(() => {
				setEmailExporting(false);

				queryClient.invalidateQueries({
					queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'exports'],
				});
			});
	}, [queryClient, submission.uuid]);

	const handleQuoteSelection = (quote) => {
		enqueueSync(quote, () => {
			dialogState.close();
		});
	};

	const handleToggle = () => {
		setSyncMenuOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setSyncMenuOpen(false);
	};

	const handleChange = (event, newValue) => {
		setActiveSync(newValue);
	};

	return (
		<EstimateFormPage>
			<React.Fragment>
				<PageTitle>Export Form</PageTitle>
				<Stack gap={2}>


					<Card variant="outlined">
						<CardHeader title="Email" />
						<CardContent>
							<ValueLabel
								label={'Sent'}
								value={
									<div style={{ display: 'flex', alignItems: 'center', lineHeight: '0.9em' }}>
										<CircleIcon color="success" sx={{ fontSize: 16, marginRight: 1 }} /> Success (03/02/2024 10:31am)
									</div>
								}
							/>

							<ActivityLog event={['export_email']} subject="App\Models\Submission" subject_id={submission.uuid} />

						</CardContent>
						<CardActions>
							<LoadingButton
								variant="outlined"
								onClick={() => exportEmail()}
								fullWidth={isMobile}
								loading={emailExporting}
							>
								Email to Office
							</LoadingButton>
							<LoadingButton
								variant="outlined"
								onClick={() => exportEmail(true)}
								fullWidth={isMobile}
								loading={emailExporting}
							>
								Email to Me
							</LoadingButton>
						</CardActions>
					</Card>

					{submission.form.key === 'estimate' && (
						<>
							<Card variant="outlined">
								<CardHeader title="iBodyShop" />
								<CardContent>
									{quotesLoading && (
										<Skeleton height={80} />
									)}

									{quotes.length && (
										<TabContext value={activeSync}>
											<TabList onChange={handleChange}>
												{quotes.map((quote, index) => (
													<Tab
														key={quote.id}
														value={+index}
														label={<div style={{ display: 'flex', alignItems: 'center', lineHeight: '0.9em' }}><SyncStatusDot status={quote.sync_status} /> {quote.estimate_number || toHumanReadable(quote.sync_status)}</div>} />
												))}
											</TabList>
											{quotes.map((quote, index) => (
												<TabPanel key={quote.id} value={index}>
													<Grid container spacing={1}>

														{quote.sync_status !== "failed" && (
															<Grid item xs={12} md={4}>
																<ValueLabel
																	label={'Status'}
																	value={
																		<div style={{ display: 'flex', alignItems: 'center', lineHeight: '0.9em' }}>
																			<SyncStatusDot status={quote.sync_status} /> {toHumanReadable(quote.sync_status)}
																		</div>
																	}
																/>
															</Grid>
														)}
														<Grid item xs={12} md={4}>
															<ValueLabel
																label={'Message'}
																value={quote.message}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="Linked Estimate"
																value={quote.estimate_number}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="Date Queued"
																value={toDate(quote.date_queued, 'dd/MM/yy hh:mm a')}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="Date Synced"
																value={toDate(quote.date_synced, 'dd/MM/yy hh:mm a')}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="IBS ID"
																value={quote.ems_id}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="Synced By"
																value={quote.synced_by_user?.name || 'System'}
															/>
														</Grid>
														<Grid item xs={6} md={4}>
															<ValueLabel
																label="Forced"
																value={quote.forced ? "Yes" : "No"}
															/>
														</Grid>
													</Grid>
													{(quote.sync_status === "completed" || quote.sync_status === "partial_failed") && (

														<Button
															variant="outlined"
															component={Link}
															to={`https://app.ibodyshop.com/#/Estimate/${quote.ems_id}/header`}
															target="_blank"
															rel="noreferrer"
															sx={{
																marginTop: 2
															}}
															fullWidth={isMobile}
														>
															View quote in IBS
														</Button>
													)}
													<ActivityLog event="sync_ibs" subject="App\Models\Quote" subject_id={quote.id} />
												</TabPanel>
											))}
										</TabContext>
									)}



								</CardContent>

								<CardActions>
									<ButtonGroup variant="contained" ref={anchorRef} fullWidth={isMobile}>
										<LoadingButton
											variant="outlined"
											onClick={() => handleSendToIBS(false)}
											loading={isProcessing}
											fullWidth={isMobile}
										>
											Send to IBS
										</LoadingButton>
										<Button
											size="small"
											sx={{
												maxWidth: 50
											}}
											aria-controls={syncMenuOpen ? 'split-button-menu' : undefined}
											aria-expanded={syncMenuOpen ? 'true' : undefined}
											aria-label="select merge strategy"
											aria-haspopup="menu"
											onClick={handleToggle}
											disabled={isProcessing}
										>
											<ArrowDropDownIcon />
										</Button>
									</ButtonGroup>
									<Popper
										sx={{
											zIndex: 1,
										}}
										open={syncMenuOpen}
										anchorEl={anchorRef.current}
										transition
									>
										{({ TransitionProps, placement }) => (
											<Grow
												{...TransitionProps}
												style={{
													transformOrigin:
														placement === 'bottom' ? 'center top' : 'center bottom',
												}}
											>
												<Paper>
													<ClickAwayListener onClickAway={handleClose}>
														<MenuList id="split-button-menu" autoFocusItem>
															<MenuItem
																onClick={(e) => {
																	handleClose(e);
																	handleSendToIBS(true);
																}}
																disabled={isProcessing}
															>
																Force Sync
															</MenuItem>
														</MenuList>
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Popper>
								</CardActions>
							</Card>

							<Card variant="outlined">
								<CardHeader title="Partscheck" />
								<CardContent>
									{quotesLoading && (
										<Skeleton height={80} />
									)}

									{quotes.length && (
										<TabContext value={activeSync}>
											<TabList onChange={handleChange}>
												{quotes.map((quote, index) => (
													<Tab
														key={quote.id}
														value={+index}
														label={<div style={{ display: 'flex', alignItems: 'center', lineHeight: '0.9em' }}>{quote.estimate_number}</div>} />
												))}
											</TabList>
											{quotes.map((quote, index) => (
												<TabPanel key={quote.id} value={index}>
													<Grid container spacing={1}>

														<Grid item xs={12} md={4}>
															<ValueLabel
																label={'Status'}
																value={
																	<div style={{ display: 'flex', alignItems: 'center', lineHeight: '0.9em' }}>
																		{toHumanReadable(quote.parts_prices_status)}
																	</div>
																}
															/>
														</Grid>
														<Grid item xs={12} md={4}>
															<ValueLabel
																label={'Pricing Attempted'}
																value={toDate(quote.date_parts_priced_attempted, 'dd/MM/yy hh:mm a')}
															/>
														</Grid>

													</Grid>
													{(quote.parts_prices_status === "PC_imported" || quote.parts_prices_status === "PC_sumitted") && (

														<Button
															variant="outlined"
															component={Link}
															to={`https://app.ibodyshop.com/#/Estimate/${quote.ems_id}/header`}
															target="_blank"
															rel="noreferrer"
															sx={{
																marginTop: 2
															}}
															fullWidth={isMobile}
														>
															View quote in Partscheck
														</Button>
													)}
													<ActivityLog event="sync_partscheck" subject="App\Models\Quote" subject_id={quote.id} />

												</TabPanel>
											))}
										</TabContext>
									)}
								</CardContent>
							</Card>
						</>
					)}
				</Stack>

				<IBSSyncDialog
					dialogState={dialogState}
					quotes={foundQuotes}
					onQuoteSelection={handleQuoteSelection}
				/>
			</React.Fragment>

			<SyncFailAlert open={syncFailAlertOpen} handleClose={() => setSyncFailAlertOpen(false)} message={syncFailMessage} />
		</EstimateFormPage>
	);
};

export default PageExport;
