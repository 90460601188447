import { useQuery } from "@tanstack/react-query";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useState } from "react";
import JobBookingDialog from "~/components/Dialogs/JobBookingDialog";
import { submissionQuery } from "~/loaders/estimateFormLoader";

// context setup
const JobBookingDialogContext = React.createContext({
	popupState: {},
	open: (id) => { },
	estimate: null
});
export const __JobBookingDialogContext = JobBookingDialogContext;

export const JobBookingDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'JobBookingDialog',
	});

	const [estimateId, setEstimateId] = useState(null);

	const { data: estimate } = useQuery(submissionQuery(estimateId));

	const open = useCallback((id) => {
		setEstimateId(id);
		popupState.open();
	}, [popupState]);

	return (
		<JobBookingDialogContext.Provider value={{ popupState, open, estimate }}>
			<>
				<JobBookingDialog
					isOpen={popupState.isOpen}
					onClose={popupState.close}
				/>
				{children}
			</>
		</JobBookingDialogContext.Provider>
	);
};

JobBookingDialogProvider.propTypes = {
}
