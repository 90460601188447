import { InputAdornment } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React, { useCallback, useMemo } from "react";
import { mergeValuesWithInital } from "~/helpers/dataHelpers";
import CustomList from "../../components/CustomList";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import { getRandomString } from "../../helpers";
import { useForm } from "../../hooks/useForm";
import { baseRRItems } from "./data/data";

const PageRemoveRefit = () => {
	const { formik, getMiscPrice, estimateMeta, isCompleted } = useForm();

	const bodyStyle = estimateMeta.bodyStyle;

	const addItem = useCallback((arrayHelpers, list) => {
		arrayHelpers.push({
			custom: true,
			key: getRandomString(8),
			label: "New Item",
			price: 1,
			qty: 1,
		});
	}, []);

	const modifiedBaseItems = useMemo(() => {
		return baseRRItems(bodyStyle).reduce((acc, item) => {
			// disable if turret is selected
			if (['coupe', 'sedan', 'hatch_wagon', '4wd', 'ute', 'euro_complex'].includes(item.key)) {
				for (let key in formik.values.panels) {
					if (key.startsWith("turret_") && formik.values.panels[key]?.replace_panel) {
						item.disabled = true;
					}
				}
			}
			acc.push(item);
			return acc;
		}, []);
	}, [bodyStyle, formik.values.panels]);

	const RRItems = useMemo(() => {
		return mergeValuesWithInital(formik.values["r&r"], modifiedBaseItems.map(item => ({
			...item,
			price: item.price ?? getMiscPrice('r&r', item.key)
		})));
	}, [formik.values, getMiscPrice, modifiedBaseItems]);


	return (
		<React.Fragment>
			<EstimateFormPage>
				<CustomList.Main
					name="r&r"
					headers={[["Item"], ["Price", 10], ["Qty", 10]]}
					baseItems={modifiedBaseItems}
					values={RRItems}
					allowAdd={addItem}
					readOnly={isCompleted}
				>
					<CustomList.Title>Remove & Refit</CustomList.Title>

					<CustomList.List>
						{(list) => {
							return list.map((item) => {
								if (item._group) {
									return (
										<CustomList.GroupHeading key={item.name}>{item.name}</CustomList.GroupHeading>
									)
								} else {
									return (
										<CustomList.ListItem key={item.key} value={item}>
											{({ index, isVirtual }) => (
												<>
													<CustomList.ListColumn>
														<CustomList.Checkbox
															label={isVirtual
																? <Field component={TextField} name={`r&r.[${index}].label`} />
																: item.label}
															value={item}
														/>
													</CustomList.ListColumn>
													<CustomList.ListColumn center>
														{(isVirtual || (item.price && !isVirtual)) && (
															<Field
																component={TextField}
																name={`r&r.[${index}].price`}
																variant="standard"
																type="number"
																disabled={item.disabled}
																sx={{ minWidth: 75 }}
																InputProps={{
																	readOnly: !isVirtual,
																	startAdornment: <InputAdornment position="start">$</InputAdornment>,
																	defaultValue: item.price || 0,
																	inputProps: { min: 0 },
																}}
															/>
														)}
													</CustomList.ListColumn>
													<CustomList.ListColumn center>
														<CellQty
															item={item}
															index={index}
														/>
													</CustomList.ListColumn>
												</>
											)}
										</CustomList.ListItem>
									)
								}

							})
						}}
					</CustomList.List>

				</CustomList.Main>
			</EstimateFormPage>
		</React.Fragment>
	);
};

const CellQty = ({ item, index }) => {

	const disabled = useMemo(() => index === null, [index]);

	return (
		<Field
			component={TextField}
			name={`r&r.[${index}].qty`}
			variant="standard"
			type="number"
			disabled={disabled || item.disabled}
			InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', defaultValue: item.qty, min: 0 }}
		/>
	);
};

export default PageRemoveRefit;
