import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useRef, useState } from "react";

const dialogDefaults = {
	alert: {
		title: "Alert",
		titleColour: "warning",
		buttonColour: "primary",
		confirmButtonText: "Ok",
		cancelButtonText: "",
		icon: <WarningIcon fontSize="large" />,
	},
	info: {
		title: "Information",
		titleColour: "info",
		buttonColour: "primary",
		confirmButtonText: "Ok",
		cancelButtonText: "",
		icon: <InfoIcon fontSize="large" />,
	},
	question: {
		title: "Question",
		titleColour: "primary",
		buttonColour: "primary",
		confirmButtonText: "Yes",
		cancelButtonText: "No",
		icon: <HelpIcon fontSize="large" />,
	},
	delete: {
		title: "Delete Confirmation",
		titleColour: "error",
		buttonColour: "error",
		confirmButtonText: "Yes",
		cancelButtonText: "No",
		icon: <DeleteIcon fontSize="large" />,
	},
};

const defaultProps = {
	...(dialogDefaults.alert),
	onConfirm: () => { },
	onCancel: () => { },
};


// context setup
const ConfirmationDialogContext = React.createContext({
	popupState: {},
	open: ({
		type = "alert",
		title = "",
		message = null, // can be string or component
		titleColour = "",
		buttonColour = "",
		confirmButtonText = "",
		cancelButtonText = "",
		icon = null,
		InputComponent = null, // Renders this component and returns its value onConfirm
		inputProps = {},
		onConfirm = () => { },
		onCancel = () => { },
	}) => { },
	close: () => { },
});
export const __ConfirmationDialogContext = ConfirmationDialogContext;


export const ConfirmDialogProvider = ({ children }) => {
	const [props, setProps] = useState({ ...defaultProps });
	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'ConfirmationDialogDialog',
	});

	const open = useCallback((props) => {
		setProps({ ...(dialogDefaults[props.type] || {}), ...props });
		setTimeout(() => {
			popupState.open();
		}, 0);
	}, [popupState]);

	const close = useCallback(() => {
		popupState.close();
	}, [popupState]);

	return (
		<ConfirmationDialogContext.Provider value={{ open, close }}>
			<>
				{popupState.isOpen && (
					<ConfirmationDialog
						open={popupState.isOpen}
						onClose={close}
						{...props}
					/>
				)}
				{children}
			</>
		</ConfirmationDialogContext.Provider>
	);
};


const ConfirmationDialog = ({ InputComponent, ...props }) => {
	const [inputValue, setInputValue] = useState("");
	const confirmRef = useRef(null);
	const inputRef = useRef(null);

	const handleConfirm = useCallback(() => {
		props.onConfirm?.(props.onClose, inputValue);
	}, [props, inputValue]);

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			TransitionProps={{
				onEntering: () => {
					if (inputRef.current) {
						inputRef.current.focus();
					} else if (confirmRef.current) {
						confirmRef.current.focus();
					}
				},
			}}
		>
			<DialogTitle color={props.titleColour} sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box display="flex" alignItems="center" gap={1}>
					{props.icon} {props.title}
				</Box>
				<IconButton aria-label="close" onClick={props.onClose} edge="end">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
				{InputComponent && (
					<Box mt={4}>
						<InputComponent
							inputRef={inputRef}
							value={inputValue}
							fullWidth
							autoFocus
							onChange={e => setInputValue(e.target.value)}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									e.preventDefault();
									handleConfirm();
								}
							}}
							{...props.inputProps}
						/>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				{props.cancelButtonText && (
					<Button onClick={(e) => props.onCancel?.(props.onClose)} color="secondary">
						{props.cancelButtonText}
					</Button>
				)}
				{props.confirmButtonText && (
					<Button ref={confirmRef} variant="contained" color={props.buttonColour} onClick={handleConfirm}>
						{props.confirmButtonText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
