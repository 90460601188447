import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useField, useFormikContext } from 'formik';

const FormikDateRangePicker = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext(); // Access Formik's context to set the field value
  const [field, meta] = useField(name); // Hook into Formik's field and meta state for the given name

  const error = meta.touched && meta.error;

  return (
    <FormControl fullWidth error={!!error}>
      <DateRangePicker
        value={field.value || [null, null]}
        onChange={(newValue) => setFieldValue(name, newValue)}
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: () => ({
            fullWidth: true,
            error: !!error,
          }),
        }}
        {...props}
      />
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default FormikDateRangePicker;
