import { useContext } from "react";
import { __BookingsContext } from "~/providers/BookingsProvider";

export function useBookings() {
	const context = useContext(__BookingsContext);
	const { startOfMonth, endOfMonth, startOfCalendar, endOfCalendar, weekStartDates, selectedDay, setSelectedDay, selectedFacilities } = context;

	return {
		startOfMonth,
		endOfMonth,
		startOfCalendar,
		endOfCalendar,
		weekStartDates,
		selectedDay,
		setSelectedDay,
		selectedFacilities
	};
}