import { blueGrey, grey, indigo, } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// assets
import colors from '~/assets/styles/_varibles.module.scss';

// const old = responsiveFontSizes(createTheme({
// 	direction: 'ltr',

// 	typography: {
// 		h4: {
// 			fontWeight: 500,
// 			textTransform: 'uppercase',
// 		},
// 		h5: {
// 			// fontWeight: 500,
// 			textTransform: 'uppercase',
// 		},
// 		h6: {
// 			marginTop: '0.4em',
// 			marginBottom: '0.25em'
// 		}
// 	},
// 	palette: {
// 		mode: 'light',
// 		primary: {
// 			main: indigo[600],
// 			dark: "#00227b",
// 			light: "#6f74dd"
// 		},
// 		"primary-on-white": {
// 			main: indigo[600],
// 			dark: "#283493",
// 			light: "#e8eaf6"
// 		},
// 		secondary: {
// 			main: blueGrey[700],
// 		},
// 		white: {
// 			main: '#fff',
// 			contrastText: indigo[600],
// 		},
// 		background: {
// 			paper: '#fff',
// 			default: '#eef2f6'
// 		},

// 	},
// }));

export const themeGenerator = () => {
	const palette = {
		colors: colors,
		heading: colors.grey900,
		paper: colors.paper,
		backgroundDefault: colors.default,
		background: colors.primaryLight,
		darkTextPrimary: colors.grey700,
		darkTextSecondary: colors.grey500,
		textDark: colors.grey900,
		menuSelected: colors.primaryDark,
		menuSelectedBack: colors.primaryLight,
		dividerColor: colors.grey600,
		success: {
			main: colors.successMain,
			light: colors.successLight,
			dark: colors.successDark
		}
	};

	const themeOptions = {
		direction: 'ltr',
		sidebarMenuWidth: 260,
		headerHeight: 64,
		palette: themePalette(palette),
		get components() {
			return componentStyleOverrides(this, palette);
		},
		typography: themeTypography(palette)
	};

	return responsiveFontSizes(createTheme(themeOptions));
};

function componentStyleOverrides(theme, palette) {
	return {
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontSize: '1.25rem',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					'&:hover': {
						backgroundColor: theme.palette[ownerState.color]?.light,
						transition: '0.2s',
					},
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					'&.MuiChip-deletable .MuiChip-deleteIcon': {
						color: 'inherit'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					color: palette.textDark,
					'&::placeholder': {
						color: palette.darkTextSecondary,
						fontSize: '0.875rem'
					}
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					'&.Mui-selected': {
						color: theme.menuSelected,
						backgroundColor: palette.menuSelectedBack,
						'&:hover': {
							backgroundColor: palette.menuSelectedBack
						},
						'& .MuiListItemIcon-root': {
							color: palette.menuSelected
						}
					},
					'&:hover': {
						backgroundColor: palette.menuSelectedBack,
						color: palette.menuSelected,
						'& .MuiListItemIcon-root': {
							color: palette.menuSelected
						}
					}
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: palette.darkTextPrimary,
					minWidth: '36px'
				}
			}
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: palette.textDark
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					'--DataGrid-containerBackground': 'transparent',
				},
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '20px',
					gap: '10px',
				}
			}
		},
		MuiSpeedDial: {
			styleOverrides: {
				root: {
					pointerEvents: 'none',

					'& button': {
						pointerEvents: 'auto',
					},

					'& .MuiSpeedDial-actionsClosed': {
						pointerEvents: 'none',
					}
				}
			}
		},
	}
}

function themePalette(theme) {
	return {
		...theme,
		mode: 'light',
		common: {
			black: theme.colors?.darkPaper
		},
		primary: {
			light: theme.colors?.primaryLight,
			light2: theme.colors?.primaryLight2,
			main: theme.colors?.primaryMain,
			dark: theme.colors?.primaryDark,
			...indigo
		},
		secondary: {
			light: theme.colors?.secondaryLight,
			main: theme.colors?.secondaryMain,
			dark: theme.colors?.secondaryDark,
			...blueGrey
		},
		grey: {
			...grey
		},
		dark: {
			light: theme.colors?.darkTextPrimary,
			main: theme.colors?.darkLevel1,
			dark: theme.colors?.darkLevel2,
			800: theme.colors?.darkBackground,
			900: theme.colors?.darkPaper
		},
		text: {
			primary: theme.darkTextPrimary,
			secondary: theme.darkTextSecondary,
			dark: theme.textDark,
			hint: theme.colors?.grey100
		},
		"primary-on-white": {
			main: indigo[600],
			dark: "#283493",
			light: "#e8eaf6"
		},
		white: {
			main: '#fff',
			contrastText: indigo[600],
		},
		background: {
			paper: theme.paper,
			default: theme.backgroundDefault
		}
	};
}

function themeTypography(theme) {
	return {
		fontFamily: theme?.customization?.fontFamily,
		h6: {
			fontWeight: 500,
			color: theme.heading,
			fontSize: '0.75rem'
		},
		h5: {
			fontSize: '0.875rem',
			color: theme.heading,
			fontWeight: 500
		},
		h4: {
			fontSize: '1rem',
			color: theme.heading,
			fontWeight: 600
		},
		h3: {
			fontSize: '1.25rem',
			color: theme.heading,
			fontWeight: 600
		},
		h2: {
			fontSize: '1.6rem',
			color: theme.heading,
			fontWeight: 700
		},
		h1: {
			fontSize: '2.125rem',
			color: theme.heading,
			fontWeight: 700
		},
		subtitle1: {
			fontSize: '0.875rem',
			fontWeight: 500,
			color: theme.textDark
		},
		subtitle2: {
			fontSize: '0.75rem',
			fontWeight: 400,
			color: theme.darkTextSecondary
		},
		caption: {
			fontSize: '0.75rem',
			color: theme.darkTextSecondary,
			fontWeight: 400
		},
		body2: {
			color: theme.darkTextPrimary
		},

		customInput: {
			marginTop: 1,
			marginBottom: 1,
			'& > label': {
				top: 23,
				left: 0,
				color: theme.grey500,
				'&[data-shrink="false"]': {
					top: 5
				}
			},
			'& > div > input': {
				padding: '30.5px 14px 11.5px !important'
			},
			'& legend': {
				display: 'none'
			},
			'& fieldset': {
				top: 0
			}
		},
		mainContent: {
			backgroundColor: theme.background,
		},

		menuCaption: {
			fontSize: '0.875rem',
			fontWeight: 500,
			color: theme.heading,
			padding: '6px',
			textTransform: 'capitalize',
			marginTop: '10px'
		},
		subMenuCaption: {
			fontSize: '0.6875rem',
			fontWeight: 500,
			color: theme.darkTextSecondary,
			textTransform: 'capitalize'
		},
		commonAvatar: {
			cursor: 'pointer',
			borderRadius: '8px'
		},
		smallAvatar: {
			width: '22px',
			height: '22px',
			fontSize: '1rem'
		},
		mediumAvatar: {
			width: '34px',
			height: '34px',
			fontSize: '1.2rem'
		},
		largeAvatar: {
			width: '44px',
			height: '44px',
			fontSize: '1.5rem'
		}
	};
}

export default themeGenerator;
