import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, IconButton, Stack, Typography, useForkRef } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { forwardRef, useMemo, useState } from "react";

const FilterMonthYearPicker = ({
	name,
	value,
	onChange,
	...props
}) => {
	const [open, setOpen] = useState(false);

	const handleOnChange = (name, value) => {
		value = value.startOf('month');
		onChange(name, value);
	}

	return (
		<Stack direction="row" alignItems='center'>
			<IconButton color="primary" size="medium" onClick={() => handleOnChange(name, value.minus({ month: 1 }))}>
				<ArrowBackIosNewIcon />
			</IconButton>
			<DatePicker
				value={value}
				onChange={val => handleOnChange(name, val)}
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				views={['year', 'month']}
				slots={{
					field: FilterMonthYearPickerButton
				}}
				slotProps={{
					field: { setOpen, open, value }
				}}
				{...props}
			/>
			<IconButton color="primary" size="medium" onClick={() => handleOnChange(name, value.plus({ month: 1 }))}>
				<ArrowForwardIosIcon />
			</IconButton>
		</Stack>
	)
}

const FilterMonthYearPickerButton = forwardRef((props, ref) => {
	const {
		value,
		setOpen,
		id,
		disabled,
		InputProps: { ref: containerRef } = {},
		inputProps: { 'aria-label': ariaLabel } = {},
	} = props;

	const handleRef = useForkRef(ref, containerRef);
	const label = useMemo(() => {
		return (
			<Stack direction="column" alignItems='center'>
				<Typography fontWeight='bold' fontSize='100%'>
					{value.toFormat('LLLL')}
				</Typography>
				<Typography fontWeight='bold' fontSize='80%'>
					{value.toFormat('yyyy')}
				</Typography>
			</Stack>
		)
	}, [value]);

	return (
		<Button
			color="inherit"
			variant="text"
			id={id}
			disabled={disabled}
			ref={handleRef}
			aria-label={ariaLabel}
			sx={{ minWidth: 100 }}
			onClick={() => setOpen?.((prev) => !prev)}
		>
			{label}
		</Button>
	);
});

export default FilterMonthYearPicker;