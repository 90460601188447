import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCallback, useMemo, useRef } from 'react';
import * as Yup from 'yup';

const slotCategories = [
  { id: 1, name: 'Full PDR', default_slots: 10 },
  { id: 2, name: 'Combo', default_slots: 0 },
  { id: 3, name: 'Turret', default_slots: 0 }
];

const validationSchema = Yup.object().shape({
  categories: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Category Name is required'),
      default_slots: Yup.number().min(0, 'Value must be 0 or more').required('Default Value is required')
    })
  )
});

const BookingCategoriesDialog = ({ onClose, onSave }) => {
  const formikRef = useRef();

  const handleOnSubmit = useCallback((values) => {
    // TODO post to API
    console.log('save values', values);
    onSave(onClose);
  }, [onClose, onSave]);

  const initialValues = useMemo(() => ({
    categories: slotCategories || []
  }), [slotCategories]);

  return (
    <Dialog open={true}>
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between">
        <div>Booking Slot Categories</div>
        <IconButton aria-label="close" onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 2, minWidth: 375 }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <>
              <Stack spacing={1} mb={2} position="relative">
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="body2" width="100%">Category Name</Typography>
                  <Typography variant="body2" width="100%">Default Slots</Typography>
                </Box>
                {values.categories.map((category, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Box width="100%">{category.name}</Box>
                    <Field
                      name={`categories.${index}.default_slots`}
                      component={TextField}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                ))}
              </Stack>
            </>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={formikRef.current?.submitForm}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BookingCategoriesDialog