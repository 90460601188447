import { Add, MoreVert } from "@mui/icons-material"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { Box, Button, Card, Divider, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo, useState } from "react"
import FilterBar from "~/components/FilterBar/FilterBar"
import FilterFacilitiesDropdown from "~/components/FilterBar/FilterFacilitiesDropdown"
import FilterMonthYearPicker from '~/components/FilterBar/FilterMonthYearPicker'
import FilterWorkProviderDropdown from "~/components/FilterBar/FilterWorkProviderDropdown"
import Page from "~/components/Page"
import PageTitle from "~/components/PageTitle"
import { stringToColor } from "~/helpers/generalHelper"
import { useJobBookingDialog } from "~/hooks/dialogs/useJobBookingDialog"
import { useBookings } from "~/hooks/useBookings"
import { useFilters } from "~/hooks/useFilters"
import { BookingsProvider } from "~/providers/BookingsProvider"
import { FilterBarProvider } from "~/providers/FilterBarProvider"
import BookingCalendarManagementDialog from "./BookingCalendarManagementDialog"
import BookingCategoriesDialog from "./BookingCategoriesDialog"
import BookingsSideDrawer from "./BookingsSideDrawer"

const bookingTypes = { pdr: 'Full PDR', combo: 'Combo', turret: 'Turret' };

const defaultFilters = {
	selected_date: DateTime.now().startOf('month').toISODate(),
	is_deleted: null,
	is_external: null,
	workprovider_uuid: null,
	facilities: [],
}

const BookingsPage = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [slotAssignDialogOpen, setSlotAssignDialogOpen] = useState(false);
	const [slotCategoriesDialogOpen, setSlotCategoriesDialogOpen] = useState(false);

	const bookingDialog = useJobBookingDialog();

	const handleMenuItemClick = useCallback((action) => () => {
		if (action) action();
		setAnchorEl(null);
	}, []);

	useEffect(() => {
		if (anchorEl && slotCategoriesDialogOpen) {
			setAnchorEl(null);
		}
	}, [slotCategoriesDialogOpen]);

	const seralizeFilters = (filters) => ({
		selected_date: filters.selected_date,
		// facilities: filters.facilities.map(f => f.uuid),
	});

	const unSeralizeFilters = (filters) => ({
		selected_date: DateTime.fromISO(filters.selected_date)
	});

	return (
		<Page title="Bookings">
			<FilterBarProvider
				defaults={defaultFilters}
				serialize={seralizeFilters}
				unserialize={unSeralizeFilters}
			>
				<Grid container marginBottom={3} spacing={2}>
					<Grid item xs={12} lg={3} xl={2}>
						<PageTitle gutterBottom={false} icon={<CalendarMonthIcon />} subtitle="Job booking calendar / availability">Bookings</PageTitle>
					</Grid>
					<Grid item xs={12} lg={9} xl={10}>
						<FilterBar>
							{({ filters, updateFilterValue, Wrap }) => {
								const selDate = DateTime.fromISO(filters.selected_date);
								console.log(filters);
								return (
									<>
										<FilterMonthYearPicker name="selected_date" value={selDate} onChange={updateFilterValue} />
										<Divider orientation='vertical' flexItem />
										<Wrap minWidth={150} grow={1.5}>
											<FilterFacilitiesDropdown value={filters.facilities} multiselect onChange={updateFilterValue} />
										</Wrap>
										<Wrap minWidth={150} grow={1}>
											<FilterWorkProviderDropdown value={filters.workprovider_uuid} onChange={updateFilterValue} />
										</Wrap>
										<Box display="flex" flex={1} justifyContent="flex-end" gap={1}>
											<Button color="primary" variant="contained" size="medium" sx={{ borderRadius: '50vh', whiteSpace: 'nowrap' }} onClick={() => bookingDialog.open()}>
												<Add /> Book Job
											</Button>
											<IconButton color="primary" size="medium" onClick={e => setAnchorEl(e.currentTarget)}>
												<MoreVert />
											</IconButton>

											<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
												<MenuItem onClick={handleMenuItemClick(() => setSlotAssignDialogOpen(true))}>
													Manage Calendar
												</MenuItem>
												<MenuItem onClick={handleMenuItemClick(() => setSlotCategoriesDialogOpen(true))}>
													Slot Defaults
												</MenuItem>
											</Menu>
										</Box>
									</>
								);
							}}
						</FilterBar>
					</Grid>
				</Grid>

				<BookingCalendarWrapper />

				{slotAssignDialogOpen && (
					<BookingCalendarManagementDialog
						onClose={() => setSlotAssignDialogOpen(false)}
						onSave={(close, data) => {
							console.log('Refresh query');
							close();
						}}
					/>
				)}

				{slotCategoriesDialogOpen && (
					<BookingCategoriesDialog
						onClose={() => setSlotCategoriesDialogOpen(false)}
						onSave={(close) => {
							console.log('Refresh query here');
							close();
						}}
					/>
				)}

			</FilterBarProvider>
		</Page>
	)
}

const BookingCalendarWrapper = () => {
	const { filters } = useFilters();

	return (
		<BookingsProvider monthDate={filters.selected_date} selectedFacilities={filters.facilities}>
			<BookingCalendar />
		</BookingsProvider>
	);
}

const BookingCalendar = () => {
	const { weekStartDates, selectedDay, setSelectedDay, selectedFacilities } = useBookings();

	return (
		<>
			<Box overflow="auto">
				{!selectedFacilities.length && (
					<Typography variant="h4" align="center" color="textSecondary" mt={3}>
						Please select facilities to view bookings
					</Typography>
				)}

				<Stack spacing={2} mb={1}>
					{weekStartDates.map((startOfWeek, i) => (
						<BookingCalendarWeek key={i} startOfWeek={startOfWeek} />
					))}
				</Stack>
			</Box>

			<BookingsSideDrawer date={selectedDay} toggleDrawer={setSelectedDay} />
		</>
	)
}

const BookingCalendarWeek = ({ startOfWeek }) => {
	const { selectedFacilities } = useBookings();

	const weekData = useMemo(() => {
		return selectedFacilities.reduce((acc, facility) => {
			acc[facility.name] = [...Array(7)].reduce((acc2, _, i) => {
				const date = startOfWeek.plus({ days: i }).toISODate();
				acc2[date] = {
					pdr: { bookings: Math.floor(Math.random() * 30), slots: 25 },
					combo: { bookings: Math.floor(Math.random() * 15), slots: 10 },
					turret: { bookings: Math.floor(Math.random() * 4), slots: 3 }
				};
				return acc2;
			}, {});
			return acc;
		}, {});
	}, [selectedFacilities, startOfWeek]);

	return (
		<Card sx={{ borderRadius: 3, minWidth: 'min-content' }}>
			{
				Object.entries(weekData).map(([facility, data], i) => {
					// loop through each facility and render the week's data
					let weekTotals = {};
					const bgColour = stringToColor(facility);

					return (
						<Box key={i} display="flex" minWidth="min-content" alignItems="stretch" minHeight={100} borderTop={theme => i > 0 ? `1px solid ${theme.palette.divider}` : null}>
							<Box width={25} display="flex" justifyContent="center" alignItems="center" bgcolor={bgColour}>
								<Box
									width={100} color={theme => theme.palette.getContrastText(bgColour)} textAlign="center" lineHeight={1} fontSize="70%" position="absolute"
									className="text-truncate"
									sx={{ transform: 'rotate(-90deg)' }}
									title={facility}
								>
									{facility}
								</Box>
							</Box>

							<Box bgcolor="primary.light" width={50} display="flex" justifyContent="center" alignItems="center">
								<Typography variant="h4">
									W{startOfWeek.weekNumber}
								</Typography>
							</Box>

							{[...Array(7)].map((_, i) => {
								// loop through each day in the week and find data for that day
								const dayDate = startOfWeek.plus({ days: i });
								const dayData = data[dayDate.toISODate()] || {};
								if (dayData) {
									weekTotals = Object.entries(dayData).reduce((acc, [key, value]) => {
										acc[key] = acc[key] || { bookings: 0, slots: 0 };
										acc[key].bookings += value.bookings;
										acc[key].slots += value.slots;
										return acc;
									}, weekTotals);
								}

								return (
									<BookingCalendarDay key={i} date={dayDate} data={dayData} />
								);
							})}

							<BookingCalendarDay key={i} date={null} data={weekTotals} totals={true} />
						</Box>
					);
				})
			}

		</Card>
	);
}

const BookingCalendarDay = ({ date, data = {}, totals = false }) => {
	const { startOfCalendar, startOfMonth, setSelectedDay } = useBookings();

	const isOutsideMonth = date
		? date.monthLong !== startOfMonth.monthLong
		: null;
	const showMonth = date
		? date.equals(startOfCalendar) || date.equals(startOfMonth) || (date.monthLong !== startOfMonth.monthLong && date.day === 1)
		: false;

	return (
		<Box
			padding={1} flex="1"
			borderLeft={theme => `1px solid ${theme.palette.divider}`}
			bgcolor={totals ? "primary.light" : (isOutsideMonth ? "grey.200" : null)}
			sx={totals ? {} : {
				cursor: 'pointer',
				'&:hover': { backgroundColor: (isOutsideMonth ? "grey.300" : "grey.100") }
			}}
			onClick={() => setSelectedDay(date)}
		>
			<Box fontSize="75%">
				<span>{totals ? "Totals" : date.toFormat('EEE, ')}</span>
				{!totals && (
					<span style={{ fontWeight: showMonth ? 'bold' : 'normal' }}>
						{showMonth ? date.toFormat('MMM dd') : date.toFormat('dd')}
					</span>
				)}
			</Box>

			<Stack direction="column" maxWidth={200} py={2} px={0.5} mx="auto" gap={0.5}>
				{Object.entries(data).map(([key, value], i) => {
					const hasCapacity = value.bookings < value.slots;
					const overCapacity = value.bookings > value.slots;
					return (
						<Stack key={i} direction="row" gap={1} alignItems="center">
							<Box width={10} height={10} bgcolor={hasCapacity ? "success.light" : "error.light"} borderRadius="50%" />
							<Box color={overCapacity ? "error.main" : null} fontSize="75%" whiteSpace="nowrap">
								{bookingTypes[key]}
							</Box>
							<Box position="relative" flex="1">
								<Box
									position="absolute" top="50%" left={0} width="100%" height="100%"
									borderTop={theme => `1px dotted ${overCapacity ? theme.palette.error.light : theme.palette.grey[500]}`}
									sx={{ transform: 'translateY(-50%)' }}
								/>
							</Box>
							<Stack direction="row" fontSize="90%" gap={1}>
								<Box width={20} textAlign="center" fontWeight="bold" color={overCapacity ? "error.main" : null}>{value.bookings}</Box>
								<Box color="grey.500" fontWeight="light">|</Box>
								<Box width={20} textAlign="center" fontWeight="light">{value.slots}</Box>
							</Stack>
						</Stack>
					);
				})}
			</Stack>
		</Box>
	)
}

export default BookingsPage