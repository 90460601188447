import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";

// context setup
const BookingsContext = React.createContext({
	startOfMonth: null,
	endOfMonth: null,
	startOfCalendar: null,
	endOfCalendar: null,
	weekStartDates: [],
	selectedDay: null,
	setSelectedDay: null,
	selectedFacilities: [],
});

export const __BookingsContext = BookingsContext;

export const BookingsProvider = ({ monthDate, selectedFacilities = [], children }) => {
	const date = useMemo(() => (
		DateTime.fromISO(monthDate).isValid ? DateTime.fromISO(monthDate) : DateTime.now()
	), [monthDate]);

	const startOfMonth = useMemo(() => date.startOf('month'), [date]);
	const endOfMonth = useMemo(() => date.endOf('month'), [date]);
	const startOfCalendar = useMemo(() => startOfMonth.minus({ days: startOfMonth.weekday % 7 }), [startOfMonth]);
	const endOfCalendar = useMemo(() => endOfMonth.plus({ days: 6 - endOfMonth.weekday }), [endOfMonth]);
	const weekStartDates = useMemo(() => {
		const weeks = [];
		let week = startOfCalendar;
		while (week <= endOfCalendar) {
			weeks.push(week);
			week = week.plus({ days: 7 });
		}
		return weeks;
	}, [startOfCalendar, endOfCalendar]);

	const [selectedDay, setSelectedDay] = useState(null);

	return (
		<BookingsContext.Provider
			value={{ startOfMonth, endOfMonth, startOfCalendar, endOfCalendar, weekStartDates, selectedDay, setSelectedDay, selectedFacilities }}
		>
			{children}
		</BookingsContext.Provider>
	);
};